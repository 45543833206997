import * as React from "react";
import {Link, graphql, HeadProps} from "gatsby"

import TemplateHead from "../../../../../components/template-head";
import Layout from "../../../../../components/layout";
import Hero from "../../../../../components/hero";
import LocationContent from "../../../../../components/location-content";
import Section from "../../../../../components/section";
import TrustpilotCarousel from "../../../../../components/trustpilot/trustpilot-carousel";
import LocationsNearest from "../../../../../components/locations-nearest";
import ArticlesGridShort from "../../../../../components/articles-grid-short";
import LocationMap from "../../../../../components/location-map";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import CleanDataParser from "../../../../../helpers/editorjs-parser";
import LocationsNearestTypes from "../../../../../components/locations-nearest-types";

type DataProps = {
  strapiLocation: any,
  strapiAdvisor: any,
  offerDefaultImage: any,
}

export default function LocationPage(props: any) {
  const location = props.data?.strapiLocation;
  const defaultImgSrc = props.location?.state?.hasDefaultImg;

  const advisor = props.data?.strapiAdvisor
  const locationOfferText = (
    <div>
      {location.offerText?.data?.offerText ? (
        JSON.parse(location.offerText?.data?.offerText).blocks.map(
          (block: any, idx: any) => CleanDataParser(block, idx)
        )
      ) : (
        <div/>
      )}
    </div>
  );

  const locationDirectionsText = (
    <div>
      {location.directions?.data?.directions ? (
        JSON.parse(location.directions?.data?.directions).blocks.map(
          (block: any, idx: any) => CleanDataParser(block, idx)
        )
      ) : (
        <div/>
      )}
    </div>
  );

  const locationOfferImage = getImage(location.offerImage?.localFile)

  const offerDefaultImage = props.data?.offerDefaultImage.edges[0]?.node?.childImageSharp?.gatsbyImageData

  return (
    <Layout as="location">
      <Hero
        heroButton={"Back to locations"}
        heroLink={"/locations/"}
        imageGlow={"/images/glow.png"}
        sectionClassName="hero-section hero-section--location pl-4 pr-4"
        contentClassName="hero-content hero-content--location"
        bgImageClassName="hero-image hero-image--location"
        breadcrumb={[
          {name: "Fenix Home", slug: "/"},
          {name: location.type?.title, slug: "/locations/" + location.type?.slug + "/"},
          {
            name: location.city?.title,
            slug: "/locations/" + location.type?.slug + "/" + location.hub?.urlPart + "/" + location.city?.cityUrl + "/"
          },
          {
            name: location.title,
            slug: location.slugFull
          },
        ]}
      >
      </Hero>
      <LocationContent
        {...props}
        defaultImgSrc={defaultImgSrc}
        locationContent={location}
        advisorData={advisor}
      />
      <LocationMap
        phone={location.displayCTA}
        description={location.description}
        locationTitle={location.title}
        address={location.address}
        postcode={location.postcode}
        phoneNotice={location.phoneNotice}
        directions={location.directions ? locationDirectionsText : ""}
        locationLat={location.geo?.latitude}
        locationLon={location.geo?.longitude}
        locationTypeSlug={location.type.slug}
      />
      <Section
        sectionClass="pl-4 pr-4 pb-5 section-container section-container--about"
      >
        <div className="dual-card dual-card--lawyer row no-gutters flex-column-reverse flex-lg-row reverse-row">
          <div
            className="dual-card__half col-12 col-lg-6 col-top-left dual-card__block bg-white pl-3 pl-md-5 pr-3 pr-md-5 pt-4 pt-md-5 pb-4 pb-md-5">
            <h2
              className="dual-card__title text-deepblue">{location.offerTitle ? location.offerTitle : "Choose Fenix. Get peace of mind. "}</h2>
            <div
              className="dual-card__content">{location.offerText ? locationOfferText : "Fenix can act as your funeral director, offering you ease and peace of mind along every step of organising a funeral for your loved one. No matter what your plans, or budget, our friendly personal advisers are there for you. They will be happy to help you contact the " + location.title + ", or help you compare it with other venues in the area. Together, we’ll create the perfect funeral. You can get in touch today, with absolutely no obligation or commitment."}</div>
          </div>
          <div className="dual-card__half col-12 col-lg-6 col-bottom-right dual-card__lawyer">
            <Link to={"/about-fenix/"}>
              <div className="lawyer-bg">
                <div className="lawyer-bg__image">
                  <GatsbyImage className={`city-card__image`}
                               image={location.offerImage ? locationOfferImage : offerDefaultImage}
                               alt="background-image-map" loading="lazy"/>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </Section>
      <TrustpilotCarousel/>
      <ArticlesGridShort
        gridClass="articles-section pr-4 pl-4 pt-5"
        locationTypeSlug={location.type.slug}
      />
      <LocationsNearest
        locationId={location.strapi_id}
        locationLat={location.geo?.latitude}
        locationLon={location.geo?.longitude}
        locationType={location.type.title}
        locationTypeSlug={location.type.slug}
      />
      <LocationsNearestTypes
        locationId={location.strapi_id}
        locationTypeSlug={location.type?.slug}
        locationCityUrl={location.city?.cityUrl}
        locationCityTitle={location.city?.title}
        locationHubUrl={location.hub?.urlPart}
        locationHubTitle={location?.hub?.title}
      />
    </Layout>
  );
};

export function Head({data: {strapiLocation}}: HeadProps<DataProps>) {
  const location = strapiLocation;
  return (
    <TemplateHead
      title={location.seo?.metaTitle ? location.seo?.metaTitle : location.title}
      shortlink={"https://fenixfuneral.co.uk/locations/" + location.type?.slug + "/" + location.hub?.urlPart + "/" + location.city?.cityUrl + "/" + location.slug + "/"}
      alternate={"https://fenixfuneral.co.uk/locations/" + location.type?.slug + "/" + location.hub?.urlPart + "/" + location.city?.cityUrl + "/" + location.slug + "/"}
      canonical={"https://fenixfuneral.co.uk/locations/" + location.type?.slug + "/" + location.hub?.urlPart + "/" + location.city?.cityUrl + "/" + location.slug + "/"}
      description={location.seo?.metaDescription ? location.seo?.metaDescription : 'Arrange beautiful funeral from £995. Funeral director with ✓ Top customer ratings 4.8 out of 5. ✓ Excellent Service ✓ Simple planning.'}
      og={{
        url: "https://fenixfuneral.co.uk/locations/" + location.type?.slug + "/" + location.hub?.urlPart + "/" + location.city?.cityUrl + "/" + location.slug + "/",
        title: location.seo?.metaTitle ? location.seo?.metaTitle : location.title,
        description: location.seo?.metaDescription ? location.seo?.metaDescription : 'Arrange beautiful funeral from £995. Funeral director with ✓ Top customer ratings 4.8 out of 5. ✓ Excellent Service ✓ Simple planning.',
        image: location?.images?.awsImage ? location?.images?.awsImage : "https://fenixfuneral.co.uk/images/Logo-Dark.svg",
      }}
    />
  );
}

export const pageQuery = graphql`
    query ($slug: String) {
        offerDefaultImage: allFile(filter: {name: {in: ["fenix_offer_image"]}}) {
            edges {
                node {
                    name
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: NONE
                        )
                    }
                }
            }
        }
        strapiLocation(slug: { eq: $slug }) {
            strapi_id
            title
            description {
                data {
                    description
                }
            }
            offerTitle
            offerText {
                data {
                    offerText
                }
            }
            offerImage {
                localFile {
                    childImageSharp {
                        gatsbyImageData(placeholder: NONE)
                    }
                }
            }
            address
            postcode
            displayCTA
            phoneNotice
            directions {
                data {
                    directions
                }
            }
            viewingTimes {
                viewingTimesText
                viewingTimesTitle
            }
            viewingTimesList {
                viewingTimesItem
            }
            facts {
                factsText {
                    data {
                        factsText
                    }
                }
                factsTitle
            }
            slug
            slugFull
            type {
                title
                slug
            }
            images {
                awsImage
            }
            hub {
                title
                urlPart
            }
            city {
                title
                cityUrl
            }
            geo {
                longitude
                latitude
            }
            seo {
                metaTitle
                metaDescription
            }
            attribute {
                aname
                avalue
                strapi_id
            }
            gplace {
                rating
                rawData
            }
        }
        strapiAdvisor(url: {eq: "emily-cross"}) {
            id
            title
            url
            fullname
            description {
                data {
                    description
                }
            }
            photo2x {
                localFile {
                    childImageSharp {
                        gatsbyImageData(width: 250, height: 250, placeholder: NONE)
                    }
                }
            }
        }
    }
`;