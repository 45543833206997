import * as React from "react";
import {useRef, useEffect, useCallback} from "react";
import {graphql, useStaticQuery} from "gatsby";

export function useFallbackImageInSSR(fallbackSrc) {
  const ref = useRef<any>(null)

  /**
   * Error happened / catched after hydration
   */
  const onError = useCallback(
    e => {
      e.target.src = fallbackSrc
    }, [fallbackSrc],
  )

  /**
   * Error happened before hydration, but catched after hydration
   */
  useEffect(() => {
    if (ref && ref.current) {
      const {complete, naturalHeight} = ref.current
      const errorLoadingImgBeforeHydration = complete && naturalHeight === 0

      if (errorLoadingImgBeforeHydration) {
        ref.current.src = fallbackSrc
      }
    }
  }, [fallbackSrc])

  return {
    ref,
    onError,
  }
}

export function getRandomFallbackImageForLocationType(type, src, id) {

  let randomNumber;
  let fallbackImage;
  const lastDigit = id % 10;
  if (type == "cemeteries") {
    if ([0, 5].includes(lastDigit)) {
      randomNumber = 1;
    } else if ([1, 6].includes(lastDigit)) {
      randomNumber = 2;
    } else if ([2, 7].includes(lastDigit)) {
      randomNumber = 3;
    } else if ([3, 8].includes(lastDigit)) {
      randomNumber = 4;
    } else if ([4, 9].includes(lastDigit)) {
      randomNumber = 5;
    } else if ([5].includes(lastDigit)) {
      randomNumber = 6;
    }
    fallbackImage = "/images/default_cemetery_" + randomNumber + ".jpg";
  } else if (type == "woodland-burial-grounds") {
    fallbackImage = "/images/default_cemetery_2.jpg";
  } else if (type == "crematoriums") {
    fallbackImage = "/images/default_cremation.svg";
  } else {
    if ([0, 4, 9].includes(lastDigit)) {
      randomNumber = 1;
    } else if ([1, 5, 8].includes(lastDigit)) {
      randomNumber = 2;
    } else if ([2, 6].includes(lastDigit)) {
      randomNumber = 3;
    } else if ([3, 7].includes(lastDigit)) {
      randomNumber = 4;
    }
    fallbackImage = "/images/default_church_" + randomNumber + ".jpg";
  }
  if (src == "" || !src || src == null) src = fallbackImage;
  if (src == "https://s3.eu-central-1.amazonaws.com/fenix-management-system-dev-media-product/default_kyrka.jpg") {
    if ([0, 4, 9].includes(lastDigit)) {
      randomNumber = 1;
    } else if ([1, 5, 8].includes(lastDigit)) {
      randomNumber = 2;
    } else if ([2, 6].includes(lastDigit)) {
      randomNumber = 3;
    } else if ([3, 7].includes(lastDigit)) {
      randomNumber = 4;
    }
    src = "/images/default_church_" + randomNumber + ".jpg";
  }

  return {src, fallbackImage};
}

export function getDefaultFallbackImageForLocationType(type, id) {

  const data = useStaticQuery(graphql`
      query {
          allLocations: allStrapiLocation(
              filter: {type: {slug: {in: ["churches", "cemeteries", "crematoriums", "woodland-burial-grounds"]}}}
              sort: {fields: title, order: ASC}) {
              nodes {
                  strapi_id
                  slug
                  type {
                      slug
                  }
              }
          }
      }
  `)

  const array = data.allLocations?.nodes;
  const totalElements = array.length;
  const elementsPerGroup = 16;
  const values = ['churches', 'cemeteries', 'woodland-burial-grounds', 'crematoriums'];

  for (let j = 0; j < values.length; j++) {
    let currentNumber = 1;

    for (let i = 0; i < totalElements; i++) {
      const object = array[i];

      if (object.type.slug === values[j]) {
        if (object.type.slug === "cemeteries") {
          object.defaultThumbImage = "/images/defaultLocation/Cemeteries/335x200/Cemetery" + currentNumber + "-335x200.jpg";
          object.defaultHeroImage = "/images/defaultLocation/Cemeteries/700x400/Cemetery" + currentNumber + "-700x400.jpg";
        } else if (object.type.slug === "crematoriums") {
          object.defaultThumbImage = "/images/defaultLocation/Crematoriums/335x200/Crematorium" + currentNumber + "-335x200.jpg";
          object.defaultHeroImage = "/images/defaultLocation/Crematoriums/700x400/Crematorium" + currentNumber + "-700x400.jpg";
        } else if (object.type.slug === "woodland-burial-grounds") {
          object.defaultThumbImage = "/images/defaultLocation/Woodlands/335x200/Woodland" + currentNumber + "-335x200.jpg";
          object.defaultHeroImage = "/images/defaultLocation/Woodlands/700x400/Woodland" + currentNumber + "-700x400.jpg";
        } else {
          object.defaultThumbImage = "/images/defaultLocation/Churches/335x200/Church" + currentNumber + "-335x200.jpg";
          object.defaultHeroImage = "/images/defaultLocation/Churches/700x400/Church" + currentNumber + "-700x400.jpg";
        }


        if ((currentNumber % elementsPerGroup) === 0) {
          currentNumber = 1;
        } else {
          currentNumber++;
        }
      }
    }
  }
  let locationItem = array.filter(obj => {
    return obj.strapi_id === id
  })

  let image = locationItem[0].defaultHeroImage
  let imageThumb = locationItem[0].defaultThumbImage

  return {image, imageThumb};
}