import * as React from "react"
import {useEffect, useState} from "react";
import {useStaticQuery, graphql} from "gatsby"

import LocationCard from "./location-card";

export default function LocationsNearest(props: any) {

  const {allStrapiLocation} = useStaticQuery(graphql`
      query {
          allStrapiLocation(
              filter: {type: {slug: {in: ["churches", "cemeteries", "crematoriums", "woodland-burial-grounds"]}}}
              sort: {fields: title, order: ASC}) {
              nodes {
                  strapi_id
                  title
                  slug
                  slugFull
                  type {
                      slug
                      title
                  }
                  hub {
                      title
                      urlPart
                  }
                  city {
                      title
                      cityUrl
                  }
                  images {
                      awsThumbnail
                      awsImage
                  }
                  geo {
                      longitude
                      latitude
                  }
              }
          }
      }
  `)

  const [locations, setLocations] = useState<any>([]);

  function distance(lat1, lon1, lat2, lon2, unit) {
    let radlat1 = Math.PI * lat1 / 180
    let radlat2 = Math.PI * lat2 / 180
    let theta = lon1 - lon2
    let radtheta = Math.PI * theta / 180
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist)
    dist = dist * 180 / Math.PI
    dist = dist * 60 * 1.1515
    if (unit == "K") {
      dist = dist * 1.609344
    }
    if (unit == "N") {
      dist = dist * 0.8684
    }
    return dist
  }

  const allStrapilocationType = allStrapiLocation.nodes.filter(function (el) {
    return el.type.slug === props.locationTypeSlug && el.strapi_id != props.locationId;
  });

  useEffect(() => {
    setLocations(allStrapilocationType)
  }, []);

  const nearbyLocations = new Array(10);
  const poslat = props.locationLat
  const poslng = props.locationLon

  for (var i = 0; i < locations.length; i++) {
    if (distance(poslat, poslng, locations[i].geo.latitude, locations[i].geo.longitude, "K") <= 500) {
      nearbyLocations[i] = {
        strapi_id: locations[i].strapi_id,
        title: locations[i].title,
        slugFull: locations[i].slugFull,
        images: locations[i].images,
        type: locations[i].type,
        hub: locations[i].hub,
        city: locations[i].city,
        distance: distance(poslat, poslng, locations[i].geo?.latitude, locations[i].geo?.longitude, "K")
      };
    }
  }

  return (
    <div className="pt-4 pl-4 pr-4 section-container section-container--nearest">
      <div className="block-wrapper">
        <div className="row no-gutters pb-2" style={stylesInline.minHeight}>
          <div className="col-12"><h2
            className="mb-1 mb-xl-3 text-white text-left pl-0 pl-xl-5"> {props.locationType} nearby</h2>
          </div>
        </div>
        <div className="church-list church-list--nearby d-flex flex-wrap justify-content-start">
          {nearbyLocations.filter(value => Object.keys(value).length !== 0).sort((a, b) => a.distance - b.distance).slice(0, 16).map((item: any, index: any) => {
            return (
              <LocationCard key={index} location={item}/>
            );
          })
          }
        </div>
      </div>
    </div>
  );
}

const stylesInline: { [key: string]: React.CSSProperties } = {
  minHeight: {
    minHeight: "unset",
  },
};