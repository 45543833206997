import * as React from "react";
import {Link, graphql} from "gatsby";

import {getDefaultFallbackImageForLocationType} from "../helpers/img-fallback";
import useIsClient from "../hooks/use-is-client";

export default function locationCard({location}) {

  const {isClient, key} = useIsClient();

  const defaultImage = getDefaultFallbackImageForLocationType(location?.type.slug, location?.strapi_id);

  return (
    <div className={"church-item"}>
      <Link
        to={location.slugFull}
        state={{hasDefaultImg: defaultImage.image}}
        className="follow-link d-block text-darkblue"
      >
        <div className={"church-item__wrapper d-flex flex-column bg-white"}>
          <div className="church-item__image">
            {isClient && <img
              style={{height: "100%"}}
              src={defaultImage.imageThumb}
              alt={location.title}
              loading="lazy"
            />}
          </div>
          <div className="church-item__content d-flex flex-column justify-content-center align-items-center">
            {location.title && <div className="">
              <h2 className="text-deepblue church-item__title text-center mb-0">{location.title}</h2>
            </div>}
          </div>
        </div>
      </Link>
    </div>
  );
};

export const query = graphql`
    fragment LocationCard on STRAPI_LOCATION {
        strapi_id
        title
        slug
        slugFull
        images {
            awsImage
            awsThumbnail
        }
        type {
            strapi_id
            slug
            title
        }
        hub {
            title
            urlPart
        }
        city {
            title
            cityUrl
        }
    }
`;