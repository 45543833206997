import * as React from "react";
import {useEffect, useState} from "react";
import {graphql, useStaticQuery, Link} from "gatsby";

import ClickReveal from "./click-reveal";
import Section from "./section";
import MapSingleLocation from "./map/single-location";

export default function LocationMap(props: any) {

  const allFuneralDirectors = useStaticQuery(graphql`
      query {
          allStrapiCity(filter: {isHub: {ne: true}}) {
              nodes {
                  geo {
                      latitude
                      longitude
                  }
                  heading {
                      main
                  }
                  hub {
                      urlPart
                      title
                  }
                  office {
                      corporateChain
                      fullAddress
                      postcode
                  }
                  title
                  cityUrl
                  isHub
                  image {
                      middle {
                          url
                          localFile {
                              childImageSharp {
                                  gatsbyImageData(
                                      placeholder: BLURRED
                                      formats: AUTO
                                      width: 750
                                      quality: 82
                                  )
                              }
                          }
                      }
                  }
              }
          }
      }
  `);

  const [funeralDirectors, setFuneralDirectors] = useState<any>([]);

  function distance(lat1, lon1, lat2, lon2, unit) {
    let radlat1 = Math.PI * lat1 / 180
    let radlat2 = Math.PI * lat2 / 180
    let theta = lon1 - lon2
    let radtheta = Math.PI * theta / 180
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist)
    dist = dist * 180 / Math.PI
    dist = dist * 60 * 1.1515
    if (unit == "K") {
      dist = dist * 1.609344
    }
    if (unit == "N") {
      dist = dist * 0.8684
    }
    return dist
  }

  useEffect(() => {
    setFuneralDirectors(allFuneralDirectors.allStrapiCity.nodes)
  }, []);

  const nearbyFuneralDirectors = new Array(5);

  for (var i = 0; i < funeralDirectors.length; i++) {
    if (distance(props.locationLat, props.locationLon, funeralDirectors[i].geo?.latitude, funeralDirectors[i].geo?.longitude, "K") <= 500) {
      nearbyFuneralDirectors[i] = {
        title: funeralDirectors[i].title,
        url: funeralDirectors[i].cityUrl,
        hub: funeralDirectors[i].hub?.urlPart,
        distance: distance(props.locationLat, props.locationLon, funeralDirectors[i].geo?.latitude, funeralDirectors[i].geo?.longitude, "K")
      };
    }
  }

  const nearbyFuneralDirectorsList = nearbyFuneralDirectors.filter(value => Object.keys(value).length !== 0).sort((a, b) => a.distance - b.distance).slice(0, 5).map((item: any, index: any) => {
    return (
      <Link key={index} to={"/funeral-directors/" + item.hub + "/" + item.url + "/"}><h5
        className="text-deepblue mb-2">{item.title}</h5></Link>
    );
  })

  return (
    <Section
      sectionClass={"pl-4 pr-4 section-container section-container--map"}
    >
      <div className="block-wrapper">
        <div className="row no-gutters flex-column-reverse flex-lg-row-reverse">
          <div
            className="col-12 col-lg-4 col-bottom-right bg-white pl-3 pl-md-5 pr-3 pr-md-5 pt-4 pt-md-5 pb-4 pb-md-5">
            {props.description &&
              <h3 className="mb-3 text-deepblue">{props.phone ? "Address & phone number" : "Address"}</h3>}
            {props.description && <div className="col-content">{props.address}</div>}
            {props.description && <div className="col-content mb-3">{props.postcode}</div>}
            {props.phone && props.phone === true &&
              <div className="col-content mb-3 text-center text-lg-left">{<ClickReveal locationText="Call Fenix" className="ctr-button"/>}</div>}
            {props.phoneNotice && <div className="col-content mb-2"><i>{props.phoneNotice}</i></div>}
            {props.email && <div className="col-content">Email: {props.email}</div>}
            {props.directions && <h3 className="mt-3 mb-4 text-deepblue">Directions</h3>}
            {props.directions && <div className="col-content">{props.directions}</div>}
            <h3 className="mb-3 text-deepblue">Funeral directors nearby</h3>
            {nearbyFuneralDirectorsList}
          </div>
          <div className="col-12 col-lg-8 col-top-left" style={stylesInline.overflowHidden}>
            <MapSingleLocation
              key={`fenix-map-single`}
              title={props.locationTitle}
              address={props.address}
              postcode={props.postcode}
              lat={props.locationLat}
              lng={props.locationLon}
              type={props.locationTypeSlug}
              hidePin={true}
            />
          </div>
        </div>
      </div>
    </Section>
  );
}

const stylesInline: { [key: string]: React.CSSProperties } = {
  overflowHidden: {
    overflow: "hidden",
    backgroundColor: "#ffffff",
  }
};