import * as React from "react";
import {Fragment} from "react";

import {useFenixContext} from "../helpers/context";
import {getDefaultFallbackImageForLocationType} from "../helpers/img-fallback";
import CleanDataParser from "../helpers/editorjs-parser";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {SwiperSlide} from "swiper/react";

export default function LocationContent(props: any) {
  const {state, dispatch} = useFenixContext();
  const images = getImage(props.advisorData?.photo2x?.localFile?.childImageSharp)
  const thisLocation = props.locationContent ? props.locationContent : {};

  const defaultImage = getDefaultFallbackImageForLocationType(thisLocation?.type.slug, thisLocation?.strapi_id);


  const locationDescriptionText = (
    <div>
      {props.locationContent.description?.data?.description ? (
        JSON.parse(props.locationContent.description?.data?.description).blocks.map(
          (block: any, idx: any) => CleanDataParser(block, idx)
        )
      ) : (
        <div/>
      )}
    </div>
  );

  const locationFactsText = (
    <div>
      {props.locationContent.facts?.factsText?.data?.factsText ? (
        JSON.parse(props.locationContent.facts?.factsText?.data?.factsText).blocks.map(
          (block: any, idx: any) => CleanDataParser(block, idx)
        )
      ) : (
        <div/>
      )}
    </div>
  );

  console.log(props.locationContent)
  return (
    <div className="location-section pl-4 pr-4 pb-4">
      <div className={"location-container bg-white"}>
        <div className="location-env text-center">
          <img
            style={{height: "100%"}}
            src={defaultImage.image}
            alt={props.locationContent?.title}
            loading="lazy"
            className="location-featured"
          />
          <div className="location-content text-deepblue">
            <div className="location-header">
              <h1 className="text-center pb-3">{props.locationContent?.title}</h1>
            </div>
            <div id={"locationText"} className={"location-text text-left mx-auto"}>
              {props.locationContent.description ? "" :
                <div className="d-flex flex-wrap justify-content-center mt-3 text-center">
                  <p className="mb-0"><b>Active</b>: Yes.&nbsp;</p>
                  <p className="mb-0">
                    <b>Address</b>: {props.locationContent?.address ? props.locationContent?.address : "Unknown"}.&nbsp;
                  </p>
                  <p className="mb-0">
                    <b>Postcode</b>: {props.locationContent?.postcode ? props.locationContent?.postcode : "Unknown"}.&nbsp;
                  </p>
                  <p className="mb-0">
                    <b>Area</b>: {props.locationContent?.city?.title ? props.locationContent?.city?.title : "Unknown"}.&nbsp;
                  </p>
                  {props.locationContent?.attribute.length === 2 ? props.locationContent?.attribute.map((item: any, index: any) => {
                    return (
                      <p key={index} className="mb-0">
                        <b>{item.aname != "built" ? "Type" : "Year built"}</b>: {item.aname != "built" ? item.aname : item.avalue != "1900" ? item.avalue : "Unknown"}.&nbsp;
                      </p>
                    );
                  }) : props.locationContent?.attribute.length == 1 ? props.locationContent?.attribute[0]?.aname != "built" ?
                      <Fragment><p className="mb-0"><b>Type</b>: {props.locationContent?.attribute[0]?.avalue}.&nbsp;</p>
                        <p
                          className="mb-0"><b>Year built</b>: Unknown.&nbsp;</p></Fragment> :
                      <Fragment><p className="mb-0"><b>Type</b>: Unknown.&nbsp;</p> <p className="mb-0"><b>Year
                        built</b>: {props.locationContent?.attribute[0]?.avalue != "1900" ? props.locationContent?.attribute[0]?.avalue : "Unknown"}.&nbsp;
                      </p></Fragment> :
                    <Fragment><p className="mb-0"><b>Type</b>: Unknown.&nbsp;</p> <p className="mb-0"><b>Year built</b>:
                      Unknown.&nbsp;</p></Fragment>}
                  <p className="mb-0"><b>Capacity</b>: Unknown.&nbsp;</p>
                </div>}
              {props.locationContent.description && <div className="mt-3">{locationDescriptionText}</div>}
            </div>
            {props.locationContent.facts?.factsText && props.locationContent.viewingTimesList &&
              <div className="row mt-5 location-text mx-auto">
                {props.locationContent.facts?.factsText &&
                  <div className="col-12 col-xl-6 text-deepblue text-left quote-wrapper pl-0">
                    <h4 className="mb-2">{props.locationContent.facts?.factsTitle}</h4>
                    {locationFactsText}
                  </div>}
                {props.locationContent.viewingTimes?.viewingTimesTitle &&
                  <div className="col-12 col-xl-6 text-deepblue text-left pr-0 pl-0">
                    <h4>{props.locationContent.viewingTimes?.viewingTimesTitle}</h4>
                    {props.locationContent.viewingTimesList &&
                      <div className="mt-3">{props.locationContent.viewingTimesList.map(function (item: any, i: any) {
                          return <p key={i} className="mb-1">{item.viewingTimesItem}</p>
                        }
                      )}
                      </div>
                    }
                    {props.locationContent.viewingTimes?.viewingTimesText &&
                      <p className="mt-3 mb-0">{props.locationContent.viewingTimes?.viewingTimesText}</p>}
                  </div>}
              </div>}
            <hr/>
            {props.locationContent.type.slug === "crematoriums" && <div className="row">
              <div className="col-12 col-lg-8 text-left mt-5">
                <h4 className="mb-4">Book this crematorium with Fenix</h4>
                <p>Arranging a funeral can be a daunting prospect. At Fenix Funeral Director we support you throughout the funeral arrangements and handle all bookings and administration on your behalf. Our transparent pricing includes all essential funeral elements, ensuring a seamless experience in one simple quotation.</p>
              </div>
              <div className="col-12 col-lg-4">
                <div>
                  <a className="gtm-strongintent">
                    <div className="mb-3">
                      <div className="text-center">
                        <GatsbyImage
                          className="rounded-circle"
                          image={images}
                          alt={"Funeral Advisor - Fenix Funeral Directors"} loading="lazy"
                        />
                      </div>
                      <h4 className="mt-4 mx-auto">Call us today to begin funeral arrangements.</h4>
                    </div>
                    <button
                      type="button" className="bg-deepblue advisor-location text-white m-auto gtm-strongintent btn btn-shevron btn-shevron--white"
                      onClick={() => dispatch({type: "modalBooking"})}
                      data-toggle="modal"
                      data-target="#bookMeetingForm"
                      title="Book consultation">
                      <span><b>Book a meeting</b></span>
                    </button>
                  </a>
                </div>
              </div>
            </div>}
            {props.locationContent.type.slug != "crematoriums" && <div className="d-flex justify-content-center mb-4">
              <button
                className="bg-deepblue text-white m-auto gtm-strongintent"
                onClick={() => dispatch({type: "modalBooking"})}
              >Book consultation
              </button>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};