import * as React from "react"
import {useStaticQuery, graphql, Link} from "gatsby"

import Section from "./section";

export default function LocationsNearestTypes(props: any) {

  const allLocations = useStaticQuery(graphql`
      query {
          allChurches: allStrapiLocation(
              filter: {type: {slug: {eq: "churches"}}}
              sort: {fields: title, order: ASC}) {
              nodes {
                  slug
                  type {
                      slug
                  }
                  hub {
                      urlPart
                  }
                  city {
                      cityUrl
                  }
              }
          }
          allCemeteries: allStrapiLocation(
              filter: {type: {slug: {eq: "cemeteries"}}}
              sort: {fields: title, order: ASC}) {
              nodes {
                  slug
                  type {
                      slug
                  }
                  hub {
                      urlPart
                  }
                  city {
                      cityUrl
                  }
              }
          }
          allCrematoriums: allStrapiLocation(
              filter: {type: {slug: {eq: "crematoriums"}}}
              sort: {fields: title, order: ASC}) {
              nodes {
                  slug
                  type {
                      slug
                  }
                  hub {
                      urlPart
                  }
                  city {
                      cityUrl
                  }
              }
          }
          allWbg: allStrapiLocation(
              filter: {type: {slug: {eq: "woodland-burial-grounds"}}}
              sort: {fields: title, order: ASC}) {
              nodes {
                  slug
                  type {
                      slug
                  }
                  hub {
                      urlPart
                  }
                  city {
                      cityUrl
                  }
              }
          }
      }
  `)


  const allChurchesCity = allLocations.allChurches?.nodes.filter(function (el) {
    return el.city?.cityUrl === props.locationCityUrl;
  });

  const allChurchesHub = allLocations.allChurches?.nodes.filter(function (el) {
    return el.hub?.urlPart === props.locationHubUrl;
  });

  const allCrematoriumsCity = allLocations.allCrematoriums?.nodes.filter(function (el) {
    return el.city?.cityUrl === props.locationCityUrl;
  });

  const allCrematoriumsHub = allLocations.allCrematoriums?.nodes.filter(function (el) {
    return el.hub?.urlPart === props.locationHubUrl;
  });

  const allWbgCity = allLocations.allWbg?.nodes.filter(function (el) {
    return el.city?.cityUrl === props.locationCityUrl;
  });

  const allWbgHub = allLocations.allWbg?.nodes.filter(function (el) {
    return el.hub?.urlPart === props.locationHubUrl;
  });

  const allCemeteriesCity = allLocations.allCemeteries?.nodes.filter(function (el) {
    return el.city?.cityUrl === props.locationCityUrl;
  });

  const allCemeteriesHub = allLocations.allCemeteries?.nodes.filter(function (el) {
    return el.hub?.urlPart === props.locationHubUrl;
  });

  return (
    <Section
      sectionClass={
        "bg-deepblue pl-4 pr-4 section-container section-container--loss pt-0 pt-xl-5"
      }
    >
      <div className="block-wrapper">
        <div className="row no-gutters">
          <div className="col-12 col-md-4 bg-transparent pl-0 pr-0 d-none d-md-block"></div>
          <div className=" col-12 col-md-8 pl-md-5 pr-md-5 pt-4 pt-md-5 pb-3 pb-md-5 bg-transparent"
               style={{zIndex: 1}}>
            <h2 className="mb-4 text-white">Locations in your area</h2>
            <div className="col-content d-flex flex-column">
              {allChurchesCity && allChurchesCity.length > 0 && <Link
                to={"/locations/churches/" + props.locationHubUrl + "/" + props.locationCityUrl + "/"}>Churches
                in {props.locationCityTitle}</Link>}
              {props.locationHubUrl !== "durham" && allChurchesHub && allChurchesHub.length > 0 && <Link
                to={"/locations/churches/" + props.locationHubUrl + "/"}>Churches in {props.locationHubTitle}</Link>}

              {allCrematoriumsCity && allCrematoriumsCity.length > 0 && <Link
                to={"/locations/crematoriums/" + props.locationHubUrl + "/" + props.locationCityUrl + "/"}>Crematoriums
                in {props.locationCityTitle}</Link>}
              {props.locationHubUrl !== "durham" && allCrematoriumsHub && allCrematoriumsHub.length > 0 && <Link
                to={"/locations/crematoriums/" + props.locationHubUrl + "/"}>Crematoriums
                in {props.locationHubTitle}</Link>}
              {allCemeteriesCity && allCemeteriesCity.length > 0 && <Link
                to={"/locations/cemeteries/" + props.locationHubUrl + "/" + props.locationCityUrl + "/"}>Cemeteries
                in {props.locationCityTitle}</Link>}
              {props.locationHubUrl !== "durham" && allCemeteriesHub && allCemeteriesHub.length > 0 && <Link
                to={"/locations/cemeteries/" + props.locationHubUrl + "/"}>Cemeteries
                in {props.locationHubTitle}</Link>}
              {allWbgCity && allWbgCity.length > 0 && <Link
                to={"/locations/woodland-burial-grounds/" + props.locationHubUrl + "/" + props.locationCityUrl + "/"}>Woodland
                Burial Grounds in {props.locationCityTitle}</Link>}
              {props.locationHubUrl !== "durham" && allWbgHub && allWbgHub.length > 0 && <Link
                to={"/locations/woodland-burial-grounds/" + props.locationHubUrl + "/"}>Woodland Burial Grounds
                in {props.locationHubTitle}</Link>}
            </div>
          </div>
        </div>
      </div>
      <img
        src="/images/stripe_1.svg"
        alt="Fenix funeral directors"
        className="stripe stripe--1 stripe--about"
      />
    </Section>
  );
}